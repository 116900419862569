
import './nav.css';
import "bootstrap/dist/css/bootstrap.css"
import React from 'react';

import "aos/dist/aos.css"
import 'bootstrap';
function Navbar() {
  return (
    <div >

      <footer class=" text-center text-lg-start">

        <div class="text-center p-3" >
          <a >© 2023 Copyright:  ABC FERRETERIA
          </a>

        </div>

      </footer>
    </div>
  );
}

export default Navbar;
